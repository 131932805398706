var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "5vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("选择生成报表的过滤条件")])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择校区" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w100",
                  attrs: {
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.formData.campusIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "campusIds", $$v)
                    },
                    expression: "formData.campusIds",
                  },
                },
                _vm._l(_vm.campusIdsList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "过滤班级" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w100",
                  attrs: {
                    multiple: "",
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.getClassAll()
                    },
                  },
                  model: {
                    value: _vm.formData.classIdsExclude,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "classIdsExclude", $$v)
                    },
                    expression: "formData.classIdsExclude",
                  },
                },
                _vm._l(_vm.classIdsExcludeList, function (item) {
                  return _c("el-option", {
                    key: item.class_id,
                    attrs: { label: item.name, value: item.class_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报表名称", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写报表名称" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary" },
              on: { click: _vm.saveSumSalaryParam },
            },
            [_vm._v("保存报表")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }