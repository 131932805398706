var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("历史报表")])]
      ),
      _c(
        "el-button",
        {
          staticClass: "tb-button mb-10",
          attrs: { type: "info", size: "small" },
          on: { click: _vm.createReport },
        },
        [_vm._v("创建报表")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, "tooltip-effect": "dark", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "快照名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.title))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "快照id", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("uuidFormat")(scope.row.sumSalaryIn.ssid))
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "报表创建时间",
              align: "center",
              prop: "createTime",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.preview(scope.row)
                          },
                        },
                      },
                      [_vm._v("老师薪酬统计")]
                    ),
                    _c(
                      "el-popover",
                      {
                        ref: "campusPover",
                        staticClass: "ml-10",
                        attrs: {
                          placement: "top",
                          width: "400",
                          trigger: "click",
                        },
                        on: { show: _vm.showCampusPopover },
                      },
                      [
                        _c("el-alert", {
                          staticClass: "mb-10",
                          attrs: {
                            title:
                              "请选择快照起止日期中包含的某一自然月进行统计",
                            type: "warning",
                            "show-icon": "",
                          },
                        }),
                        _c(
                          "el-form",
                          {
                            ref: "formCampus",
                            attrs: {
                              model: _vm.formCampus,
                              rules: _vm.campusRules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "统计月份", prop: "time" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择要统计的月份",
                                    },
                                    model: {
                                      value: _vm.formCampus.time,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formCampus, "time", $$v)
                                      },
                                      expression: "formCampus.time",
                                    },
                                  },
                                  _vm._l(_vm.timeOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.campus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "tb-button",
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v("校区薪酬统计")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: this.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("seles-filtration", {
        attrs: {
          show: _vm.filtrationDialog,
          minTime: _vm.minTime,
          maxTime: _vm.maxTime,
          ssid: _vm.ssid,
          createReportInside: _vm.createReportInside,
        },
        on: {
          close: function ($event) {
            _vm.filtrationDialog = false
          },
          updateHistory: _vm.updateHistory,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }