<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>历史报表</span>
    </div>
    <!-- minTime：{{minTime}}
    <hr>
    maxTime：{{maxTime}}
    <hr> -->
    <el-button class="tb-button mb-10" type="info" @click="createReport" size="small">创建报表</el-button>
    <el-table
      :data="dataList"
      tooltip-effect="dark"
      border
      style="width:100%">
      <el-table-column label="快照名称" align="center">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column label="快照id" align="center">
        <template slot-scope="scope">{{ scope.row.sumSalaryIn.ssid | uuidFormat }}</template>
      </el-table-column>
      <el-table-column label="报表创建时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="scope">
          <el-button type="text" @click="preview(scope.row)">老师薪酬统计</el-button>
          <el-popover
            placement="top"
            width="400"
            trigger="click"
            ref="campusPover"
            class="ml-10"
            @show="showCampusPopover"
          >
            <el-alert
              title="请选择快照起止日期中包含的某一自然月进行统计"
              type="warning"
              class="mb-10"
              show-icon>
            </el-alert>
            <el-form ref="formCampus" :model="formCampus" :rules="campusRules">
              <el-form-item label="统计月份" prop="time">
                <!-- <el-date-picker
                  v-model="formCampus.time"
                  type="month"
                  value-format="yyyyMM"
                  :picker-options="pickerOptions"
                  placeholder="请选择要统计的月份">
                </el-date-picker> -->
                <el-select v-model="formCampus.time" placeholder="请选择要统计的月份">
                  <el-option
                    v-for="item in timeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button type="primary" size="mini" @click="campus(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" class="tb-button" type="text">校区薪酬统计</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="this.pagination.total"
    ></el-pagination>
    <seles-filtration :show="filtrationDialog" @close="filtrationDialog=false" :minTime="minTime" :maxTime="maxTime" :ssid="ssid" :createReportInside="createReportInside" @updateHistory="updateHistory"></seles-filtration>
  </el-dialog>
</template>

<script>
import DatePicker from '@/components/date-picker'
import { getListSumSalaryParam, computeSalaryByCampus } from '@/api/salary'
import selesFiltration from '../../views/dollars/components/seleFiltration'
import { formatDateYMD, formatDateDay, chineseYMD } from '@/utils/formatDate'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    reportData: {
      default: () => {
        return []
      },
      type: Array
    },
    minTime: {
      default: '',
      type: String
    },
    maxTime: {
      default: '',
      type: String
    },
    ssid: {
      default: '',
      type: String
    },
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      dateTime: [],
      minStartTime: '',
      maxStartTime: '',
      filtrationDialog: false,
      createReportInside: false,
      formCampus: {},
      timeOptions: [],
      campusRules: {
        time: [{ required: true, message: '请选择要统计的月份', trigger: 'blur' }]
      },
    }
  },
  computed: {
    pickerOptions() {
      let that = this
      return {
        disabledDate(time) {
          // console.log('time', formatDateYMD(time))
          // let startTime = new Date(that.minTime.substring(0, 10)).getTime()
          // let endTime = new Date(that.maxTime.substring(0, 10)).getTime
          // console.log('startTime', startTime)
          // console.log('now', Date.now())
          // console.log('time.getTime', time.getTime())
          let a = that.natureMonth(new Date(that.minTime), 1)
          return time.getTime() <= new Date(that.maxTime).getTime()
        }
      }
    }
  },
  components: {DatePicker, selesFiltration},
  methods: {
    showCampusPopover() {
      this.formCampus = {}
      this.$refs.formCampus.resetFields()
    },

    natureMonth(curDate, step){
      let targetDateLastDay = new Date(curDate.getFullYear(), curDate.getMonth() + step + 1, 0);
      return new Date(curDate.getFullYear(), curDate.getMonth() + step, Math.min(curDate.getDate(), targetDateLastDay.getDate()));
    },

    createReport() {
      this.createReportInside = true
      this.filtrationDialog = true
    },

    updateHistory() {
      this.dataList = []
      this.search()
    },

    campus(row) {
      this.$refs.formCampus.validate(valid => {
        if (valid) {
          computeSalaryByCampus({
            ssid: JSON.parse(row.param).ssid,
            month: this.formCampus.time
          }).then(res => {
            if (res.state === 'success') {
              this.close()
              this.$router.push({
              path: '/statistics/dollars-campus',
                query: {
                  id: JSON.parse(row.param).ssid,
                  min: this.minTime,
                  max: this.maxTime,
                  param: row.param,
                  month: this.formCampus.time
                }
              })
            }
          })
        }
      })
    },

    openDialog() {
      this.pagination.currentPage = 1
      this.timeOptions = []
      this.getListSumSalaryParam()
      // minTime：2021-02-01 00:00:00
      // maxTime：2021-03-03 23:59:59
      let minTime = this.minTime.substring(0, 10)
      let maxTime = this.maxTime.substring(0, 10)
      let startNow = new Date(this.minTime.substring(0, 10)) // 快照起始日期
      let endNow = new Date(this.maxTime.substring(0, 10)) // 快照结束日期
      let startYear = startNow.getFullYear() // 起止日期年份
      let endYear = endNow.getFullYear() // 结束日期年份
      let startMonth = endNow.getMonth() // 起止日期月份
      let endMonth = endNow.getMonth() // 结束日期月份
      let startMonthStartDate = new Date(startYear, startMonth, 1) // 起始日期本月的开始时间
      // let startMonthEndDate = new Date(startYear, Number(startMonth)+1, 0) // 起始日期本月的结束时间
      // let endMonthStartDate = new Date(endYear, endMonth, 1) // 结束日期本月的开始时间
      let endMonthEndDate = new Date(endYear, Number(endMonth)+1, 0) // 结束日期本月的结束时间

      let minDay = minTime.split('-')[2]
      let maxDay = maxTime.split('-')[2]
      let minMonth = minTime.split('-')[1]
      let maxMonth = maxTime.split('-')[1]
      let mixYear = maxTime.split('-')[0]
      let maxYear = maxTime.split('-')[0]

      if (Number(minDay) > Number(formatDateDay(startMonthStartDate))) {
        // console.log('不是一号开始的')
        minMonth = this.setMonth(Number(minMonth) + 1)
      }

      if (Number(maxDay) < Number(formatDateDay(endMonthEndDate))) {
        // console.log('结束日期不是当前月的最后一天')
        maxMonth = this.setMonth(Number(maxMonth) - 1)
      }

      let minA = `${mixYear}-${minMonth}`
      let maxA = `${maxYear}-${maxMonth}`

      this.timeOptions = this.getMonthBetween(minA, maxA)
      // console.log('timeOptions', this.timeOptions)

    },

    setMonth(num) {
      // let a = Number(num)
      if (0 < num < 10) {
        return '0'+num
      } else if (num > 10) {
        return num
      } else if (num <= 0) {
        return Number(num) + 12
      }
    },

    getMonthBetween(start,end) {
      var result = [];
      var s = start.split("-");
      var e = end.split("-");
      var min = new Date();
      var max = new Date();
      min.setFullYear(s[0],s[1]);
      max.setFullYear(e[0],e[1]);
      var curr = min;
      while(curr <= max){
        var month = curr.getMonth();
        //month=month==0?12:month;
        let setMonth = month > 10 ? month : '0' + month
        var str=curr.getFullYear()+(setMonth);
        let lable = curr.getFullYear()+'-'+(setMonth);
        var s=curr.getFullYear()+"-0";
        if(str==s){
          str=curr.getFullYear()+"-12";
        }
        result.push({
          value: str,
          label: chineseYMD(new Date(lable))
        });
        curr.setMonth(month+1);
      }
      return result;
    },

    preview(row) {
      this.close()
      this.$router.push({
        path: '/statistics/dollars-detail',
        query: {
          id: JSON.parse(row.param).ssid,
          min: this.minTime,
          max: this.maxTime,
          param: row.param
        }
      })
    },

    search() {
      this.pagination.currentPage = 1
      this.getListSumSalaryParam()
    },

    getListSumSalaryParam() {
      getListSumSalaryParam({
        ssid: this.ssid,
        // createTimeMin: this.minStartTime,
        // createTimeMax: this.maxStartTime,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }).then(res => {
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      })
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getSumSalary()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.getSumSalary()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/style/dialog.scss";
</style>
