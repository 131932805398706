<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    top="5vh"
    @open="openDialog"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择生成报表的过滤条件</span>
    </div>

    <el-form ref="formData" :model="formData" :rules="rules" label-position="top">
      <el-form-item label="选择校区">
        <el-select v-model="formData.campusIds" multiple filterable placeholder="请选择" class="w100" clearable>
          <el-option
            v-for="item in campusIdsList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="过滤班级">
        <el-select
          v-model="formData.classIdsExclude"
          multiple
          filterable
          clearable
          placeholder="请选择"
          class="w100"
          @click.native="getClassAll()"
        >
          <el-option
            v-for="item in classIdsExcludeList"
            :key="item.class_id"
            :label="item.name"
            :value="item.class_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报表名称" prop="title">
        <el-input v-model="formData.title" placeholder="请填写报表名称"></el-input>
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" class="tb-button" @click="saveSumSalaryParam">保存报表</el-button>
    </el-row>

  </el-dialog>
</template>

<script>
import { getCampusAll } from '@/api/senate/campus'
import { getClassAll } from '@/api/senate/class'
import { saveSumSalaryParam } from '@/api/salary'

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    minTime: {
      default: '',
      type: String
    },
    maxTime: {
      default: '',
      type: String
    },
    ssid: {
      default: '',
      type: String
    },
    type: {
      default: '',
      type: String
    },
    createReportInside: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data() {
    return{
      formData: {},
      rules: {
        title: [{ required: true, message: '请填写报表名称', trigger: 'blur' }],
      },
      campusIdsList: [],
      classIdsExcludeList: [],
    }
  },
  methods: {
    openDialog() {
      this.formData = {}
      this.getCampusAll()
    },

    close() {
      this.$emit('close')
    },

    // 保存报表
    saveSumSalaryParam() {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          await saveSumSalaryParam({
            title: this.formData.title,
            ssid: this.ssid,
            classIdsExclude: this.formData.classIdsExclude ? this.formData.classIdsExclude : null,
            campusIds: this.formData.campusIds ? this.formData.campusIds : null
          })
          window.$msg('保存成功')
          this.close()
          if (this.createReportInside) {
            setTimeout(() => {
              this.$emit('updateHistory')
            }, 100)
          }
        }
      })

    },

    async getCampusAll () {
      const res = await getCampusAll()
      this.campusIdsList = res.body
    },

    async getClassAll () {
      const res = await getClassAll({
        pageNum: 1,
        pageSize: 1000,
        campusIdSet: this.formData.campusIds,
        minLessonTime: this.minTime,
        maxLessonTime: this.maxTime,
        needCount: true
      })
      this.classIdsExcludeList = res.body.list
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';

</style>
